import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import styled from 'styled-components';
import { pc, sp, tab } from '../../media';
import { Sun, Moon } from 'react-feather'
import useDarkMode from "use-dark-mode"


    
const ColorContainer = styled.label`
    height: 20px;
    padding: 4px; 
    transition: 0.4s;
    // :hover {
    //     border-radius: 50%;
    //     box-shadow: 0 1px 1px rgb(0 0 0 / 50%);
    // }
`


const ColorThemeSwitcher = (props) => {
    const darkMode = useDarkMode(undefined)

    const [isDark, setIsDark] = useState(false)

    useEffect(() => {
        setIsDark(localStorage.getItem("darkMode"))
    }, [])


    const onChange = (e) => {
        darkMode.toggle()
    }
    if (darkMode.value === undefined) return null;

    return (
        <div>
          <ColorContainer>
            { 
                darkMode.value ?
                <Moon color={"var(--textNormal)"} size={20} style={{cursor: "pointer"}} /> :
                <Sun color={"var(--textNormal)"} size={20} style={{cursor: "pointer"}} />

            }
            <input
              type="checkbox"
              onChange={e => onChange(e)}
              style={{display: "none", cursor: "cursor"}}
            />{' '}
          </ColorContainer>
        </div>
    )
}

export default ColorThemeSwitcher
