import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default ({ filename, className, style }) => (

  <StaticQuery

    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              publicURL
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 50
                  width: 800
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `}

    render={(data) => {

      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename)
      })

      if (!image) return
      const imageData = getImage(image.node.childImageSharp)


      if(filename.split('.')[1] === "svg") {
          return <img src={image.node.publicURL} alt={image.node.name} className={className} style={style} />
      }
      
      return <GatsbyImage image={imageData} className={className} style={style} alt={filename.split('.')[0]} />
    }}
  />
)
