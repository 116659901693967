import React, { useState, useRef } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import styled from 'styled-components';
import { pc, sp, tab } from '../../media';
import { IntlContextConsumer, changeLocale, useIntl } from "gatsby-plugin-intl"


const LanguageText = styled.span`
    cursor: pointer;
    color: var(--textNormal);
`
const LanguageButton = styled.button`
    position: absolute;
    top: -1000px;
    visibility: none;
`

const StyledMenuItem = styled.span`
  text-decoration: none;
  margin: 0px;
  margin-top: 2px;
  margin-bottom: 2px;
  paddinng: 4px;
  border-radius: 0.5rem;
  font-family: 'GT Haptik Regular';
  width: fit-content;
  :hover {
    border-radius: 8px;
  }
  @media (max-width: 960px) {
  }
`

const StyledExternalLink = styled.a`
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: block;
  width: 100%;
  cursor: pointer;
  :focus {
    outline: 0;
    opacity: 0.9;
  }
  :hover {
    * {
    }
  }
`

const StyledTitle = styled.p`
  display: block;
  font-weight: ${({ active }) => active && 500};
  border-radius: 8px;
  text-decoration: none;
  margin: 0;
  padding: 0px;
  padding: 4px;
  width: fit-content;
  color: var(--textNormal);
  transition: transform 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  :hover {
    cursor: pointer;
    transform: translate3d(2px, 2px, 10px);
  }
`

const FloatMenuNav = styled.nav`
  border-radius: 0.5rem;
  padding: 12px;
  flex-direction: column;
  position: absolute;
  top: 1.65rem;
  left: -1rem;
  min-width: 80px;
  width: 100%;
  border-radius: 8px;
  height: 0;
  overflow: hidden;
  transition: max-height 0.4s 3s, opacity 0.6s;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.04); z-index: 999;
  backdrop-filter: blur(120px);
  background-color: var(--bg);
  p {
    padding: 4px;
  }
  display: ${({ open }) => (open ? 'block' : 'none')};
  height: ${({ open }) => (open ? 'auto' : 0)};
`


const LanguageSwitcher = (props) => {
    const data = useStaticQuery(graphql`
        query {
          allDataJson {
            edges {
              node {
                languageItems {
                    text
                    language
                }
              }
            }
          }
        }
      `)
    const intl = useIntl();
    console.log(data)
    const languageTexts = data.allDataJson.edges[0].node.languageItems;
    const [isOpen, updateIsOpen] = useState(false)
    const ref = useRef(null)

    const onFocusLanguageButton = () => {
        console.log('focus')
        ref.current.focus()

    }


    const onFocusLanguage = () => {
        updateIsOpen(true)
    }

    const onBlurLanguage = () => {
        updateIsOpen(false)
    }


    return (
        <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
                <div style={{ position:"relative"}} >
                    <LanguageText onClick={() => onFocusLanguageButton()} onMouseOver={() => onFocusLanguageButton()} > {languageTexts.find(item => item.language == currentLocale).text}  </LanguageText>
                    <LanguageButton 
                        ref={ref}
                        onBlur={() => onBlurLanguage() } 
                        onFocus={() => onFocusLanguage()} 
                        style={{ marginRight: '0.25rem' }}
                    > { languageTexts.find(item => item.language == currentLocale).text } 
                    </LanguageButton>
                    <FloatMenuNav open={isOpen}>
                        {languages.map((language, index) => (
                            <StyledMenuItem tabindex={index} key={index}>
                                <StyledTitle onMouseDown={() => changeLocale(language)}>
                                    {languageTexts.find(item => item.language == language).text }
                                </StyledTitle>
                            </StyledMenuItem>
                        ))}
                    </FloatMenuNav>
                </div>
            }
        </IntlContextConsumer>
    )
}

export default LanguageSwitcher;
