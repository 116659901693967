import React from 'react';
import styled from 'styled-components';
import { pc, sp, tab } from '../../media';

const CT = styled.div`
    width: 100%;
`

const CTInner = styled.div`
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 20px;
    ${sp`
        max-width: 80%;
    `}
    ${tab`
        max-width: 720px;
    `}
    ${pc`
        max-width: 1020px;
    `}
`

const Container = (props) => {

    return (
        <CT className={props.className}>
            <CTInner>
              {props.children}
            </CTInner>
        </CT>
    )
}

export default Container;
