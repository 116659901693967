import React from 'react';
import styled from 'styled-components';
import { pc, sp, tab } from '../../media';
import Image from "../Image";
import Container from "../../components/styledComponents/container"


const SectionHeadText = styled.h2`
    margin: 0;
    margin-top: 40px;
    color: var(--text-section-title-color);
    font-family: Futura PT,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    -webkit-letter-spacing: -0.015em;
    -moz-letter-spacing: -0.015em;
    -ms-letter-spacing: -0.015em;
    letter-spacing: -0.015em;
    line-height: 3rem;
    ${sp`
        font-size: 32px;
        font-weight: 600;
    `}
    ${tab`
        font-size: 32px;
        font-weight: 600;
    `}
    ${pc`
        font-size: 32px;
        font-weight: 600;
    `}
`

const SectionInfoText = styled.p`
    color: var(--text-section-title-info-color);
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin: 0;
    line-height: 1.25rem;
    font-size: 1rem;
`

const SectionInfoContainer = styled.div`
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    color: var(--textNormal);
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.5;
    margin: 0;
    max-width: 40rem;
    z-index: 1;
    white-space: pre-wrap;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
`

const SectionInner = styled.div`
    max-width: 800px;
    display: grid;
    gap: 1.5rem;
    position: relative;
    z-index: 2;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
`

const Index = (props) => {
    const item = props.section

    return (
      <Container  className={props.bgCls}>
        <SectionInner>
            <SectionHeadText>{item.sectionHeadText}</SectionHeadText>
            <Image filename={item.imagePath} />
            <SectionInfoContainer> 
                <SectionInfoText>{item.sectionInfoText}</SectionInfoText>
            </SectionInfoContainer>
        </SectionInner >
      </Container>
    )
};

export default Index;
