import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import { useIntl, injectIntl } from "gatsby-plugin-intl"
import Container from "../components/styledComponents/container"
import { pc, sp, tab } from '../media';

const HideSmall = styled.span`
  @media (max-width: 960px) {
    display: none;
  }
`
const ShowSmall = styled.span`
  @media (min-width: 960px) {
    display: none;
  }
`

const HowToWorkContainer = styled.div`
    display: flex;
`

const HowToWorkArea = styled.div`
        font-family: Futura PT,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
        h1 {
            color: var(--text-section-title-color);
            ${sp`
                font-size: 2em;
            `}
            font-size: 54px;
        }
        h3 {
            color: var(--textFaceText);
            margin-top: 10px;
            ${sp`
                font-size: 1.5em;
                font-weight: 400;
            `}
            ${tab`
                font-size: 2rem;
                font-weight: 600;
            `}
            ${pc`
                font-size: 2rem;
                font-weight: 600;
            `}
        }
        span {
            font-size: 20px;
        }
        p {
            color: var(--text-section-title-info-color);
            // font-size: 1em;
            line-height: 1.75em;
            // border-image: linear-gradient(to right, #3abaff 0%, #3abaff 100%);
            // border-image: linear-gradient(to right, #34435E 0%, #34435E 100%);
            // border-image-slice: 1;
            // border-width: 3px;
            margin: 0;
            padding: 20px 20px 0px 20px;
            counter-increment: section;
            position: relative;
            // &:before {
            //     content: counter(section);
            //     color: #fff;
            //     // color: var(--button-blue);
            //     position: absolute;
            //     border-radius: 50%;
            //     padding: 10px;
            //     height: 24px;
            //     width: 24px;
            //     background-color: #34435E;
            //     // background-color: var(--button-blue);
            //     text-align: center;
            //     line-height: 24px;
            //     font-size: 1em;
            // }
            .note_text {
                font-size: 16px;
                font-weight: normal;
                line-height: 1.5rem;
                color: var(--textFaceInfoText);
                text-align: left;
                ${sp`
                `}
                ${tab`
                    text-align: left;
                    font-size: 1.25rem;
                    font-weight: normal;
                `}
                ${pc`
                    font-size: 1.5rem;
                    font-size: 16px;
                    font-weight: normal;
                `}



            }
            padding-left: 40px;
        }
        p:first-child {
            border-top: 0;
            border-top-right-radius:0;
            border-top-left-radius:0;
        }
        p:last-child {
            border-bottom-right-radius:0;
            border-bottom-left-radius:0;
        }
        // @media (max-width: 960px) {
          p{
            margin: 0 auto;
            width: 85%;
          }
          p:nth-child(n) {
              // border-left: 3px solid;
              padding-right: 0;
              white-space:break-spaces;
              &:before {
                  right: 100%; 
                  margin-right: -20px;
              }
          }
        // }
        // @media(min-width: 960px) {
        // margin: .5rem auto 1.45rem;
        // max-width: 920px;
        //     p {
        //         border-top: 3px solid;
        //     }
        //     p:nth-child(odd) {
        //         border-right: 3px solid;
        //         padding-left: 0;
        //
        //         &:before {
        //             left: 100%; 
        //             margin-left: -19px;
        //             top: calc(50% - 20px);
        //         }
        //     }
        //     p:nth-child(even) {
        //         border-left: 3px solid;
        //         padding-right: 0;
        //
        //         &:before {
        //             right: 100%; 
        //             top: calc(50% - 20px);
        //             margin-right: -19px;
        //         }
        //     }
        // }
`

const HowToWork = (props) => {
    const intl = useIntl()
    const howToWorks = intl.messages.howToWorks
    const howToWorksTitle = intl.messages.howToWorksTitle
    
    return (
      <Container  className={props.bgCls}>
            <HowToWorkArea >
                <h1 style={{"textAlign": "center", "paddingTop": "106px"}} > {howToWorksTitle} </h1>
                <HideSmall>
                    <HowToWorkContainer >
                        {howToWorks.map((node, i) => (
                            <p key={i}>
                                {node.textList.map((x, j) => (
                                        j == 0 
                                        ?
                                        <h3 key={(i + 100) * j + 1} className={x.className} style={{"marginBottom": 16,  "textAlign": "left"}} >{x.text} <br/></h3>
                                        :
                                        <span key={(i + 100) * j + 1} style={{"marginBottom": 0, "display": "block"}} className={x.className}>{x.text} </span>
                                ))}
                                <br />
                            </p>
                        ))}
                    </HowToWorkContainer>
                </HideSmall>
                <ShowSmall>
                    <div>
                        {howToWorks.map((node, i) => (
                            <p key={i}>

                                {node.textList.map((x, j) => (
                                        j == 0 
                                        ?
                                        <h3 key={(i + 100) * j + 1} className={x.className} style={{"marginBottom": 16,  "textAlign": "left"}} >{x.text} <br/></h3>
                                        :
                                        <span key={(i + 100) * j + 1} style={{"marginBottom": 0, "display": "block"}} className={x.className}>{x.text} </span>
                                ))}

                            <br />
                            </p>
                        ))}
                    </div>
                </ShowSmall>
            </HowToWorkArea>
        </Container>
    )
}

export default HowToWork
