import React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid';


const PdfText = styled.a`
    color: #e6007a;
    color: #428bca;
    color: #666;
    color: var(--textLink);
    text-decoration: none;
    font-size: 14px;
`

export default class LinkText extends React.Component {
    render() {
        const uuid = uuidv4();
        const props = this.props
        if(props.href) {
            return (
                <PdfText target="_blank" rel="noopener noreferrer" {...props} >
                {props.text}
                </PdfText >
            )
        }
        return (
            <PdfText  {...props} >
                {props.text}
            </PdfText >
        )
    }
}
