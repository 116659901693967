import React from 'react';
import styled from 'styled-components';
import { pc, sp, tab } from '../../media';
import { useIntl, injectIntl } from "gatsby-plugin-intl"
import Menu from "./menu"
import Dropdown from "./dropdown"
import LanguageSwitcher from "./languageSwitcher"
import ColorThemeSwitcher from "./colorThemeSwitcher"
import LinkButton from "./../styledComponents/LinkButton";
import LinkText from "../styledComponents/LinkText"
import Image from "../Image";


const LogoContainer = styled.div`
    width: 140px;
    ${sp`
        width: 80px;
    `}
    -webkit-font-smoothing antialiased;
`

const Logo = styled.div`
    width: 140px;
    ${sp`
        width: 80px;
    `}
`

const LogoDark = styled.div`
    width: 140px;
    ${sp`
        width: 80px;
    `}
`


const HeaderInner = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    -webkit-box-align: center;
    align-items: center;
    height: 6rem;
    position: relative;
    ${sp`
      gap: 2rem;
      padding-left: 20px;
      padding-right: 20px;
    `}
    ${tab`
      height: 7.5rem;    
      grid-template-columns: repeat(3, 1fr);
      padding-left: 0px;
      padding-right: 0px;
    `}
    ${pc`
    width: 100%;
        margin-left: 0;
        margin-right: 0;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    `}
`
const MenuWrapper = styled.div`
    height: 2rem;
    display: block;
    max-width: 90%;
    ${sp`
        max-width: 100%;
    `}
    ${tab`
        width: 100%;
        max-width: 100%;
    `}
    ${pc`
    display: none;
    `}
`
const Header = styled.header`
    display: none;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 75.5rem;
    padding: 0 2.5rem;
    margin: 0 auto;
    ${sp`
        max-width: 100%;
    `}
    ${tab`
    `}
    ${pc`
        display: flex;
    `}
`
const HeaderSmart = styled.header`
    display: flex;
    max-width: 75.5rem;
    padding: 0rem;
    margin: 0 auto;
    margin-bottom: 70px;
    ${sp`
        max-width: 100%;
    `}
    ${tab`
        max-width: 90%;
        -webkit-box-pack: initial;
        justify-content: initial;
        padding-left: 1rem;
        padding-right: 1rem;
    `}
    ${pc`
        display: none;
    `}
`

const Index = (props) => {
    const intl = useIntl();
    const headers = intl.messages.headers;


    return (
        <React.Fragment>
            <Header>
                <HeaderInner>
                    <LogoContainer>
                        <Logo className="hide-bydark">
                            <Image filename="PaperLogo_light.png" />
                        </Logo>
                        <LogoDark className="show-bydark">
                            <Image filename="PaperLogo_dark.png" />
                        </LogoDark>
                    </LogoContainer>
                    <div />
                    <div style={{display: "flex", gap: "0px 24px", justifyContent:"flex-end", alignItems: "center"}}>
                        <LanguageSwitcher />
                        <ColorThemeSwitcher />
                        <LinkButton href={headers.signInUrl} text={headers.signIn} style={{minWidth: "120px"}} addArrow={true} outline={true} filled={false} isSignIn={true} />
                    </div>
                </HeaderInner>
            </Header>

            <HeaderSmart>
                <MenuWrapper >
                    <HeaderInner>
                        <LogoContainer>
                            <Logo className="hide-bydark">
                                <Image filename="PaperLogo_light.png" />
                            </Logo>
                            <LogoDark className="show-bydark">
                                <Image filename="PaperLogo_dark.png" />
                            </LogoDark>
                        </LogoContainer>
                        <Menu >
                            <LinkButton href={headers.signInUrl} text={headers.signIn} style={{minWidth: "120px"}} addArrow={true} outline={true} filled={false} isSignIn={true}  />
                            <ColorThemeSwitcher />
                            <LanguageSwitcher />
                        </Menu>
                    </HeaderInner>
                </MenuWrapper>
            </HeaderSmart>
        </React.Fragment>
    )

};

export default Index;
