import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"
import { pc, sp, tab } from '../../media';
import { useIntl, injectIntl } from "gatsby-plugin-intl"

const DropDownContainer = styled.div`
    display: ${props => props.isOpen ? 'block' : 'none'};
    left: 50%;
    padding-top: 0.75rem;
    position: absolute;
    top: 100%;
    visibility: visible;
    z-index: 10;
    -webkit-transform: translateX(calc(-50% + 0px));
    -ms-transform: translateX(calc(-50% + 0px));
    transform: translateX(calc(-50% + 0px));
    width: 37.5rem;

    ul {
        margin: 0;
        list-style: none;
    }
    li {
        padding: 0 1.5rem;
        padding-bottom: 0.75rem;
    }
`

const Index = (props) => {

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
    }, [isOpen])

    return (
        <DropDownContainer onClick={() => setIsOpen(!isOpen)}>
            <ul>
                <li>
                    aljldfkajs
                </li>
            </ul>
        </DropDownContainer>
    )


};

export default Index;

