import React from 'react';
import styled from 'styled-components';
import { pc, sp, tab } from '../media';
import { useIntl, injectIntl } from "gatsby-plugin-intl"
import LinkButton from "./styledComponents/LinkButton";

const Container = styled.div`
    background: var(--bg);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 1rem ;
    border-top: 1px solid var(--border-color);
    justify-content: center;
    place-content: space-between;
    height: 2.4375rem ;
    display: none;
    ${sp`
        z-index: 2;
        display: flex;
    `}
`

const ButtonContainer = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    grid-gap: 0.25rem ;
    gap: 0.25rem ;
    border: 1px solid var(--border-color);
    padding: 0.5rem 0.625rem ;
    border-radius: 0.1875rem ;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
    color: var(--textNormal);
    color: var(--face-emtext-color);
    border-color: var(--face-emtext-color);
    font-size: .6875rem;
`


const Bottom = () => {
    const intl = useIntl();
    const headers = intl.messages.headers;

    return (
        <Container>
            <ButtonContainer href={headers.signInUrl}>{headers.signIn} </ButtonContainer>
        </Container>

    )

}
export default Bottom;
