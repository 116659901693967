import React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid';


const LinePath = styled.path`
    // opacity: 0;
    // transition: opacity 150ms cubic-bezier(0.215,0.61,0.355,1);
    opacity: var(--arrowLineOpacity);
    transition: opacity var(--hoverTransition,var(--arrowHoverTransition));
`

const TipPath = styled.path`
    // transform: none;
    // transition: transform 150ms cubic-bezier(0.215,0.61,0.355,1);
    transform: var(--arrowTipTransform);
    transition: transform var(--hoverTransition,var(--arrowHoverTransition));
`

const ArrowSvg = styled.svg`
    position: relative;
    top: 1px;
    margin-left: var(--arrowSpacing);
    stroke-width: 2px;
    fill: none;
    stroke: currentColor;
`

const Button = styled.a`
    --hoverTransition: 150ms cubic-bezier(0.215,0.61,0.355,1);
    --arrowSpacing: 5px;
    --arrowHoverTransition: 150ms cubic-bezier(0.215,0.61,0.355,1);
    --arrowHoverOffset: translateX(3px);
    --arrowTipTransform: none;
    --arrowLineOpacity: 0;

    text-decoration: none;
    background: ${({filled}) => {
        return filled ? "var(--filled-button-background-color)" : "var(--button-background-color)"
    }};
    color: ${({filled}) => filled ? "var(--buttonTextColor-reverse)" : "var(--textButton)"};
    padding: 12px 16px;
    position: relative;
    border-radius: 100px;

    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    min-width: 120px;
    padding: 14px 10px;
    font-size: 15px;
    margin: 12px 6px 6px 0px;
    font-family: var(--font-family);
    font-weight: 600;
    transition: 0.4s;


    :hover {
        background: ${({filled}) => filled ? "var(--filled-button-background-color-hover)" : "var(--bg-reverse)"};
        color: ${({filled}) => filled ? "var(--buttonTextColor-reverse)" : "var(--textNormal-reverse)"};
        --hoverTransition: 150ms cubic-bezier(0.215,0.61,0.355,1);
        --arrowLineOpacity: 1;
        --arrowTipTransform: translate(4px, 0px);
    }
`

const OutlineButton = styled(Button)`
    border-radius: 100px;
    border-color: ${({filled}) => filled ? "var(--filled-button-background-color)" : "var(--textButtonOutline)"};
    border-width: 2px;
    border-style: solid;
`

const SignInButton = styled(Button)`
    border-radius: 100px;
    border-color: ${({filled}) => filled ? "var(--filled-button-background-color)" : "var(--textButtonOutline)"};
    border-color: var(--face-emtext-color);
    color: var(--face-emtext-color);
    border-width: 1px;
    border-style: solid;

    :hover {
        background: var(--button-background-color);
        border-color: var(--face-emtext-color);
        color: var(--face-emtext-color);
    }
`


export default class LinkButton extends React.Component {
    render() {
        const uuid = uuidv4();
        const props = this.props
        let button;
        const arrow = <ArrowSvg width="10" height="15" viewBox="0 0 10 10">
                        <g fill-rule="evenodd">
                            <LinePath d="M0 5h7" />
                            <TipPath d="M1 1l4 4-4 4" />
                        </g>
                    </ArrowSvg> 


        if(props.isSignIn) {
            button = <SignInButton
                          data-tip={props.toolTip ? props.toolTip : ""}
                          href={props.href} 
                          rel="noopener noreferrer" 
                          style={props.style}
                          className={props.className} 
                          filled={props.filled}
                          target={props.target ? props.target : "_blank"}
                          download={props.download}
                      > {props.text}
                        {
                        props.addArrow && arrow
                        }
                      </SignInButton>
        } else if(!props.outline) {
            button = <Button
                  data-tip={props.toolTip ? props.toolTip : ""}
                  href={props.href} 
                  rel="noopener noreferrer" 
                  style={props.style}
                  className={props.className} 
                  filled={props.filled}
                  target={props.target ? props.target : "_blank"}
                  download={props.download}
                  >
                  {props.text}
                {
                    props.addArrow && arrow
                        }
              </Button>
        } else {
            button = <OutlineButton
                  data-tip={props.toolTip ? props.toolTip : ""}
                  href={props.href} 
                  rel="noopener noreferrer" 
                  style={props.style}
                  className={props.className} 
                  filled={props.filled}
                  target={props.target ? props.target : "_blank"}
                  download={props.download}
                  >
                  {props.text}
                {
                    props.addArrow && arrow
                        }
              </OutlineButton>

        }
        return (
            <>
                {button}
            </>
        )
    }
}
