import React from 'react';
import styled from 'styled-components';
import { pc, sp, tab } from '../media';
import { useIntl, injectIntl } from "gatsby-plugin-intl"
import LinkButton from "./styledComponents/LinkButton";


const InfoText = styled.p`
    font-size: 18px;
    font-weight: normal;
    line-height: 1.5rem;
    color: var(--textFaceInfoText);
    text-align: left;
    ${sp`
        margin-block-end: 0px;
    `}
    ${tab`
        text-align: left;
        font-size: 1.25rem;
        font-weight: normal;
    `}
    ${pc`
        font-size: 1.5rem;
        font-size: 18px;
        font-weight: normal;
    `}
`

const FaceTextContainer = styled.div`
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
`
const FaceText = styled.h1`
    display: inline;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0em;
    margin-inline-end: 0em;
    margin: 0;
    line-height: 2.5rem;
    color: var(--textFaceText);
    text-align: left;
    font-weight: 800;
    ${tab`
        font-size: 3rem;
        line-height: 3rem;
    `}
    ${pc`
        font-size: 3.5rem;
        line-height: 4rem;
    `}
`
const FaceBottomInner = styled.div`
    display: -webkit-box;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    width: 100%;
    grid-area: 2 / 1 / auto / span 4;
    justify-self: center;
    display: flex;
    flex-direction: column;
    ${sp`
        width: 50%;
        place-self: center;
        align-items: center;
    `}
    ${tab`
        flex-direction: row;
        width: 50%;
        place-self: center start;
        -webkit-box-pack: start;
        justify-content: start;
    `}
    ${pc`
        width: 50%;
        place-self: center end;
        grid-area: 1 / 9 / auto / span 4;
        -webkit-box-pack: end;
        justify-content: end;
    `}
`

const Inner = styled.div`
    grid-column: 1 / span 4;
    justify-self: center;  
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    ${sp`
        padding-bottom: 0rem;
    `}
    ${tab`
        justify-self: start;
        padding-bottom: 4.5rem;
        padding-top: 4.5rem;
    `}
    ${pc`
        grid-column: 1 / span 8;
        justify-self: start;
        padding-bottom: 4.5rem;
        padding-top: 4.5rem;
    `}
`

const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    ${sp`
        max-width: 90%;
        gap: 0rem;
    `}
    ${tab`
        max-width: 90%;
        gap: 0rem;
    `}
    ${pc`
        max-width: 75.5rem;
        padding: 0 2.5rem;
        margin: 0 auto;
        gap: 2rem;
        grid-template-columns: repeat(12, 1fr);
        height: 80vh;
    `}
`

const Face = () => {
    const intl = useIntl();
    const faces = intl.messages.faces;

    return (
        <Container>
            <Inner>
                <FaceTextContainer>
                    {
                        faces.topTextList.map(item => (<FaceText className={item.className}>{item.text}</FaceText>))
                    }
                </FaceTextContainer>
                <InfoText>{faces.infoText} </InfoText>
                <InfoText>{faces.infoText2} </InfoText>
            </Inner>
            <FaceBottomInner>
                <LinkButton href={faces.startLink} text={faces.buttonText} addArrow={true} outline={true} filled={true} />
                <LinkButton href={faces.contactSalesLink} text={faces.contactSales} addArrow={true} outline={false} />
            </FaceBottomInner>
        </Container>

    )

}
export default Face;
