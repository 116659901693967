import React from 'react';
import styled from 'styled-components';
import { pc, sp, tab } from '../media';
import Image from "./Image";
import { useIntl } from "gatsby-plugin-intl";

const PlansFeature = styled.div`
    display: grid;
    margin-top: 60px;
    margin-bottom: 60px;
    grid-template-columns: repeat(3,33.3%);
    row-gap: 10px;
    ${sp`
        grid-template-columns: repeat(1,100%);
    `}
    ${tab`
        grid-template-columns: repeat(2,50%);

    `}
    ${pc`
        box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.1);
    `}
    grid-template-rows: auto;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
`

const PlansFeatureTable = styled.div`
    ${tab`
        box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.1);
    `}
    padding-bottom: 30px;
`

const PlansFeatureTitleContainer = styled.div`
    display: contents;
    text-align: center;
`
const PlansFeatureText = styled.div`
    font-size: 22px;
    line-height: 1.36365;
    font-weight: 900;
    padding-bottom: 8px;
    padding-top: 36px;
    color: var(--textFaceText);
`

const PlansFeatureTextSub = styled.div`
    font-size: 12px;
    font-weight: 300;
`

const PlansFeaturePriceContainer = styled.div`
    display: contents;
    text-align: center;
`
const PlansFeaturePriceText = styled.div`
    font-size: 22px;
    line-height: 1.36365;
    font-weight: 700;
    padding-bottom: 8px;
    padding-top: 6px;
`

const PlansFeaturePriceTextSub = styled.span`
    padding-bottom: 32px;
    font-size: 14px;
    font-weight: 600;
    color: var(--textFaceText);
`

const PlansFeatureUpgradeContainer = styled.div`
    text-align: center;
`
const PlansFeatureUpgradeButton = styled.button`
    display: inline-block;
    background: #0078d4;
    font-size: 14px;
    padding: 5px 20px;
    margin: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    color: #fff;
    border: 1px solid #2196f3;
    transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    border-radius: 3px;
    &:hover {
        color: #fff;
        border-color: #0d89ec;
    }
    &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #a6d5fa;

    }
`
const PlansFeatureUpgradeText = styled.div`
    font-size: 22px;
    line-height: 1.36365;
    font-weight: 900;
    padding-bottom: 8px;
    padding-top: 36px;
`
const PlansFeatureUpgradeTextSub = styled.div`
    padding-bottom: 32px;
`
const PlansFeaturePriceTextSubContainer = styled.div`
    height: 36px;
    display: block;  
    margin-left: 20px;
    color: var(--textFaceText);
    font-size: 12px;
    line-height: 1.46668;
    font-weight: 200;
    padding-left: 20px;
    padding-right: 20px;
`
const PlansFeatureBasePriceContainer = styled.div`
    display: contents;
    text-align: right;
`
const PlansFeatureBasePriceText = styled.div`
    font-size: 12px;
    line-height: 1.36365;
    font-weight: 500;
    padding-bottom: 8px;
`

const PlansFeatureBasePriceTextSub = styled.div`
    padding-bottom: 32px;
`

const PlansFeatureFuncContainer = styled.div`
    display: contents;
    text-align: center;
`
const PlansFeatureFuncText = styled.div`
    padding-top: 4px;
    padding-bottom: 12px;
    font-weight: 700;
`

const PlansFeatureFuncTextSub = styled.div`
    padding-bottom: 32px;
`

const PlansFeatureFuncTextChildren = styled.div`
    color: var(--textFaceText);
`
const PlansFeatureFuncTextChildrenUl = styled.ul`
    font-size: 1rem;
    list-style-type: none;
    margin: 0;
    margin-top: 20px;
    padding: 0 0 0 1.5rem;
`
const PlansFeatureFuncTextChildrenLi = styled.li`
    font-size: 15px;
    text-align: left;
    margin: 0 0 0.5rem;
    position: relative;
    list-style: none;
    color: var(--textFaceText);
`

const NonPriceContainer = styled.div`
    height: 36px;
    display: block;
    margin-left: 20px;
    color: var(--textFaceText);
    font-size: 12px;
    line-height: 1.46668;
    font-weight: 200;
    padding-left: 20px;
    padding-right: 20px;
`

const SectionHeadText = styled.h2`
    margin: 0;
    margin-top: 40px;
    color: var(--text-section-title-color);
    font-family: Futura PT,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    -webkit-letter-spacing: -0.015em;
    -moz-letter-spacing: -0.015em;
    -ms-letter-spacing: -0.015em;
    letter-spacing: -0.015em;
    line-height: 3rem;
    text-align: center;
    ${sp`
        font-size: 2.4375rem;
        font-weight: 600;
    `}
    ${tab`
        font-size: 2.4375rem;
        font-weight: 600;
    `}
    ${pc`
        font-size: 2.4375rem;
        font-weight: 600;
    `}
`

const PlansFeatureFuncTextChildrenWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2px;
    & .gatsby-image-wrapper {
        min-width: 16px;
    }
`

const PriceGrid = (props) => {
    const intl = useIntl();
    const priceData = intl.messages.priceData;
    const priceTables = priceData.priceTables;

    const onClickButton = (isContact) => {
        if(isContact) {
            const contactSalesLink = intl.messages.faces.contactSalesLink
            window.open(contactSalesLink, '_blank');
        } else {
            const signInUrl = intl.messages.headers.signInUrl
            window.open(signInUrl, '_blank');
        }
    }


    return (
        <>
        <SectionHeadText>{priceData.sectionHeadText}</SectionHeadText>
        <PlansFeature>
            {priceTables.map(prices => (
                <PlansFeatureTable>
                    <PlansFeatureTitleContainer>
                        <PlansFeatureText>
                            {prices.title}
                        </PlansFeatureText>
                    </PlansFeatureTitleContainer>
                    <PlansFeaturePriceContainer>
                        <PlansFeaturePriceText>
                            {prices.price ?
                                <>
                                {prices.priceUnit ?   // enterpriceかどうか
                                <span style={{"fontSize": "40px", "color": "#0078d4"}}>{ prices.price } </span>:
                                <div style={{"fontSize": "14px", "fontWeight": 400, "height": "54px", "lineHeight": "54px", "color": "#616161"}}>{ prices.price } </div>
                                }
                                <PlansFeaturePriceTextSub> { prices.priceUnit} </PlansFeaturePriceTextSub>
                                <PlansFeaturePriceTextSubContainer>
                                    <span>{prices.subtitle}</span><br />
                                    <span>{ prices.price_year} {prices.priceYearUnit}</span>
                                </PlansFeaturePriceTextSubContainer>
                                </>
                                :
                                <>
                                    <div style={{"fontSize": "40px", "height": "55px"}}> </div>
                                    <NonPriceContainer>
                                        <span style={{"whiteSpace": "pre-wrap"}}>{prices.subtitle}</span><br />
                                        <span >{prices.inquiryText} </span>
                                    </NonPriceContainer>
                                </>
                            }
                        </PlansFeaturePriceText>
                    </PlansFeaturePriceContainer>
                    <PlansFeatureUpgradeContainer>
                        <PlansFeatureUpgradeButton onClick={() => onClickButton(prices.isContact)}> {prices.buttonText} </PlansFeatureUpgradeButton>
                    </PlansFeatureUpgradeContainer>

                    <PlansFeatureFuncContainer>
                        <PlansFeatureFuncText>
                            {prices.func}
                        </PlansFeatureFuncText>
                        <PlansFeatureFuncTextChildren>
                            <span style={{"fontSize": "17px", "fontWeight": "bold" }}>{prices.funcInfo}</span>
                            <PlansFeatureFuncTextChildrenUl>
                            {prices.funcList.map(funcText => (
                                <PlansFeatureFuncTextChildrenWrap>
                                
                                <Image filename="check.png" style={{"height": "16px", "width": "16px"}} />
                                    <PlansFeatureFuncTextChildrenLi>
                                        {funcText}
                                    </PlansFeatureFuncTextChildrenLi>
                                </PlansFeatureFuncTextChildrenWrap>
                                ))}
                            </PlansFeatureFuncTextChildrenUl>
                        </PlansFeatureFuncTextChildren>
                    </PlansFeatureFuncContainer>
                </PlansFeatureTable>
            ))}
        </PlansFeature>
        </>
    )
}

export default PriceGrid;
