import * as React from "react"
import { useIntl } from "gatsby-plugin-intl";
// import 'semantic-ui-css/semantic.min.css';

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import Face from "../components/face"
import Container from "../components/styledComponents/container"
import HeadSection from "../components/headSection"
import PriceGrid from "../components/priceGrid"
import Section from "../components/section"
import Footer from "../components/footer"
import Bottom from "../components/bottom"
import HowToWork from "../components/how2Work"


const Index = ({ data, location }) => {
    const intl = useIntl();
    const sections = intl.messages.sections;
    const bgOddColorCls = "oddBg"
    const bgEvenColorCls = "evenBg"

  return (
    <Layout location={location} title="paper">
      <Seo title="Bank Invoice Paper"/>
      <Header />
      <Face bgCls={bgEvenColorCls}/>
      <HowToWork bgCls={((sections.items.length + 1) % 0 == 0) ? bgEvenColorCls : bgOddColorCls} />
          {
            sections.items.map((section, sectionIndex) => (
              <Section section={section} 
                bgCls={((sectionIndex + 1) % 2 == 1) ? bgEvenColorCls : bgOddColorCls}
                />
            ))
          }
      <Container>
          <PriceGrid />
          <HeadSection bgCls={((sections.items.length + 2) % 2 == 0) ? bgEvenColorCls : bgOddColorCls} />
      </Container>
      <Footer />
      <Bottom />
    </Layout>
  )
}

export default Index
