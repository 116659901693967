import React from 'react';
import styled from 'styled-components';
import LanguageSwitcher from "./languageSwitcher"
import LinkButton from "./../styledComponents/LinkButton";
import { Link } from "gatsby"
import { pc, sp, tab } from '../../media';
import { useIntl, injectIntl } from "gatsby-plugin-intl"
import { slide as Menu } from "react-burger-menu";


const Index = (props) => {
    const children = props.children
    const intl = useIntl();
    const headers = intl.messages.headers;

    return (
            <Menu {...props} right>
                <div style={{display: "flex", gap: "12px 0px", justifyContent:"flex-end", alignItems: "start", flexDirection: "column"}}>
                    {children}
                </div>
            </Menu>
           )


};

export default Index;
