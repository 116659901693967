import React from 'react';
import styled from 'styled-components';
import LinkButton from "./styledComponents/LinkButton";
import { pc, sp, tab } from '../media';
import { useIntl, injectIntl } from "gatsby-plugin-intl"

const InfoText = styled.p`
    color: var(--text-head-section-info-color);
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: left;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 2rem;
`

const HeadText = styled.h3`
    color: var(--text-head-section-color);
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 1.9375rem;
    line-height: 2.75rem;
    text-align: left;
`

const RowInner = styled.div`
    flex: 1;
`

const ButtonContainer = styled.div`
    display: -webkit-box;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    justify-self: center;
    display: flex;
    flex-direction: column;
    ${sp`
        place-self: center;
        align-items: center;
    `}
    ${tab`
        flex-direction: row;
        place-self: center start;
        -webkit-box-pack: start;
        justify-content: start;
    `}
    ${pc`
        margin-left: 40px;
        place-self: center end;
        -webkit-box-pack: end;
        justify-content: end;
    `}
`

const Row = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
    align-items: center;
    line-height: var(--bs-body-line-height);
    ${pc`
        flex-direction: row;
    `}
`

const HeadSection = () => {
    const intl = useIntl();
    const headSections = intl.messages.headSections;
    const faces = intl.messages.faces;

    return (
        <Row>
            <RowInner>
                <HeadText> {headSections.headText}</HeadText>
            </RowInner>
            <ButtonContainer>
                <LinkButton href={faces.startLink} text={faces.buttonText} addArrow={true} outline={true} filled={true} />
                <LinkButton href={faces.contactSalesLink} text={faces.contactSales} addArrow={true} outline={false} />
            </ButtonContainer>
        </Row>

    )

}
export default HeadSection;
