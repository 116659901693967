import React from 'react';
import styled from 'styled-components';
import { pc, sp, tab } from '../../media';
import { Link, useIntl, injectIntl } from "gatsby-plugin-intl"
import LinkText from "../styledComponents/LinkText"
import Image from "../Image";

const Footer = styled.footer`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
    ${sp`
      flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    `}
`


const FooterPartContainer = styled.div`
    // margin-left: 20px;
    // margin-right: 20px;
    display: flex;
    flex-direction: row;
    ${sp`
      flex-direction: column;
    `}
`



const Space = styled.div`
    flex: 1;
`

const Space4SP = styled.div`
    display: none;
    ${sp`
        display: block;
        width: 100%;
        height : 60px;
    `}
`

const FooterText = styled.span`
    padding-left: 10px;
    padding-right: 10px;
`

const CopyRight = styled.span`
    color: var(--copyright-color);
`

const ImageContainerS = styled.div`
    margin-top: 10px;
    width: 160px;
    align-self: center;
    ${pc`
        display: none;
    `}
`

const ImageContainer = styled.div`
    width: 160px;
    align-self: center;
    ${sp`
        display: none;
    `}
    ${tab`
        display: none;
    `}
`

const ToLinkText = styled(Link)`
    color: var(--textLink);
    text-decoration: none;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px; 
    padding-top: 5px;
    padding-bottom: 5px;
    align-self: center;
`


const Index = (props) => {
    const intl = useIntl();
    const footers = intl.messages.footers;
    const style = {paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, alignSelf: "center" }


    return (
        <Footer>
          <FooterPartContainer>
            <CopyRight style={style}>
              &copy; BankInvoice
            </CopyRight>
            <LinkText style={style} href={footers.contactUrl} text={footers.contact} /> 
          </FooterPartContainer>
          <Space />
          <ImageContainer>
              <Image filename="bankinvoice_logo_BK.png" />
          </ImageContainer>
          <Space />
          <FooterPartContainer>
            <ToLinkText to={footers.privacyUrl}>{footers.privacy}</ToLinkText>
            <ToLinkText to={footers.termsUrl}>{footers.terms}</ToLinkText>
            <ToLinkText to={footers.noticeUrl}>{footers.notice}</ToLinkText>
          </FooterPartContainer>
          <ImageContainerS>
              <Image filename="bankinvoice_logo_BK.png" />
          </ImageContainerS>
          <Space4SP />
        </Footer>
    )
};

export default Index;

